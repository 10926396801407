export type Action =
  | { type: "setWine"; payload: any }
  | { type: any; payload?: any };

export type Dispatch = (action: Action) => void;

export type WineState = {
  loading: boolean;
  producers?: Producer[];
  producersTotal?: number;
  baseWine?: BaseWine;
  baseWines?: BaseWine[];
  baseWinesTotal?: number;
  wineVintages?: any[];
  wineVintagesTotal?: number;
  wines?: Wine[];
  winesTotal?: number;
  error?: string;
};

export type IWineContext = {
  state: WineState;
  dispatch: Dispatch;
};

export type BaseWine = {
  wine_name: string;
  wine_name_2: string;
  grape_variety: string;
  color: string;
  region_1: string;
  country: string;
  lwin7: string;
  slug: string;
  producer: Producer;
  wines: Wine[];
  wine_vintages: any[];
  importers: Importer[];
}

type ProducerAssociationStats = {
  base_wine_total: number;
  wine_vintage_total: number;
  wine_total: number;
}

export type Producer = {
  id: string;
  producer_name: string;
  region_2: string;
  country: string;
  slug: string;
  importer: string;
  base_wines: BaseWine[];
  matching_wines: number;
  matching_vintages: number;
  association_stats: ProducerAssociationStats;
  regional_info?: string;
  display_imports?: boolean;
}

export type Importer = {
  id: number;
  slug: string;
  name: string;
  address: string;
  country: string;
  telephone: string;
  email: string;
  website: string;
  description: string;
  notes: string;
  regional_info: string;
  producers: Producer[];
  image_url: string;
  hide_for_producers: boolean;
}

export type Wine = {
  id: number;
  wine_name: string;
  release_price?: string;
  color: string;
  score_raw: string;
  importer: string;
  producer: Producer;
  producer_commentary: string;
  tasting_note: string;
  vintage: number;
  region_1: string;
  region_2: string;
  country: string;
  article_id: number;
  drinking_window_begin: number,
  drinking_window_end: string;
  slug: string;
  review_date: string;
  author: string;
  base_wine_id: number;
  producer_name?: string;
  tasted_on?: string;
  article_date: string;
  price?: string;
  grape_variety?: string;
  base_wine_prices?: [{price: string, vintage: number}];
};

export const wineReducerInitialState = {
  loading: false,
  producers: undefined,
  baseWine: undefined,
  baseWines: undefined,
  wineVintages: undefined,
  wines: undefined,
  error: undefined,
};

export const wineReducer = (state: WineState, action: Action) => {
  switch (action.type) {
    case "loadingWine": {
      return {
        ...state,
        loading: true,
      };
    }
    case "setBaseWine": {
      return {
        ...state,
        baseWine: action.payload.data,
        error: undefined,
        loading: false,
      };
    }
    case "setProducers": {
      return {
        ...state,
        producers: action.payload.data.producers.map(producer => {
          return {
            ...producer._source,
          }
        }),
        producersTotal: action.payload.data.producers_total,
        error: undefined,
        loading: false
      }
    }
    case "setBaseWines": {
      const baseWines = action.payload.data.base_wines.map(base_wine => {
        let formattedBaseWine = base_wine._source;
        if (base_wine.inner_hits && base_wine.inner_hits.wine_vintages) {
          formattedBaseWine = {
            ...formattedBaseWine,
            wine_vintages: base_wine.inner_hits.wine_vintages.hits.hits.map((h) => h._source)
          }
        }

        if (base_wine.inner_hits && base_wine.inner_hits.wines) {
          formattedBaseWine = {
            ...formattedBaseWine,
            wines: base_wine.inner_hits.wines.hits.hits.map((h) => h._source)
          }
        }

        return formattedBaseWine;
      });
      return {
        ...state,
        baseWines: baseWines,
        baseWinesTotal: action.payload.data.base_wines_total,
        error: undefined,
        loading: false
      };
    }
    case "setWines": {
      const wines = action.payload.data?.wines?.map(wine => wine._source);
      return {
        ...state,
        wines: wines,
        winesTotal: action.payload.data?.wines_total,
        error: undefined,
        loading: false
      };
    }
    case "setWineVintages": {
      const wineVintages = action.payload.data.wine_vintages.map(wine => {
        const rawWineVintage = wine._source;
        if (wine.inner_hits && wine.inner_hits.wines) {
          return {
            ...rawWineVintage,
            wines: wine.inner_hits.wines.hits.hits.map((h) => h._source)
          }
        } else {
          return rawWineVintage;
        }
      }).reduce((acc, wineVintage) => {
        const duplicate = acc.find(v =>
          v.vintage === wineVintage.vintage &&
          JSON.stringify(v.base_wine) === JSON.stringify(wineVintage.base_wine)
        );

        if (!duplicate) {
          acc.push(wineVintage);
        }

        return acc;
      }, []);
      return {
        ...state,
        wineVintages: wineVintages,
        wineVintagesTotal: action.payload.data.wine_vintages_total,
        error: undefined,
        loading: false
      };
    }
    case "setWineError": {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};
